<template>
  <div>
    <v-app-bar dense fixed app color="purple darken-4" dark>
      <v-btn to="/menu_manage" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>กติกาประจำงวด</v-toolbar-title>
      <v-spacer></v-spacer>
      <strong class="white--text">{{ HostName }}</strong>
    </v-app-bar>
    <div
      style="max-width: 850px;margin: auto;border: 1px solid grey;margin-top: 10px;"
      ref="PrintMe"
    >
      <header
        style="color:white; background-color:indigo; padding: 30px; font-weight: bold; text-align: center; font-size: 1.5em !important;"
      >
        งวด {{ getAbbrFullMonthPeriodThai($route.query.period) }} (ปิด
        {{ CloseTime }} น.)
      </header>

      <div
        style="color:darkred;font-weight: bold;font-size: 1.4em !important;text-align: center; padding-top: 15px;"
        v-if="HP_3.length>0"
      >
        3 ตัวบน เต็ง-โต๊ด ( อั้น )
      </div>
      <div
        style="color:indigo;font-weight: bold;font-size: 1.4em !important;text-align: center; padding: 8px;"
        v-for="(HP, index) in HP_3"
        :key="'A'+index"
      >
        {{ get_3hp_text(HP) }}
      </div>
      <div
        style="color:darkred;font-weight: bold;font-size: 1.4em !important;text-align: center; padding-top: 15px;"
        v-if="HP_2.length>0"
      >
        2 บน+ล่าง ( พร้อมตัวกลับอั้น )
      </div>
      <div
        style="color:indigo;font-weight: bold;font-size: 1.4em !important;text-align: center; padding: 8px;"
        v-for="(HP, index) in HP_2"
        :key="'B' +index"
      >
        {{ get_2hp_text(HP) }}
      </div>
    </div>

    <div class="text-center mt-3">
      <v-btn color="indigo" rounded dark @click="print()">
        <v-icon class="mr-2">mdi-image-size-select-actual</v-icon>
        บันทึกรูปภาพ
      </v-btn>
    </div>
    <!-- <img :src="output" /> -->
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

export default {
  name: "NewPeriodSuccess",
  mixins: [globalFunctionMixin],
  components: {
    // components,
  },
  //mounted: {},
  created() {
    //โหลด ตาราง
    this.PeriodDT = this.$route.query.period;
    this.fetch_data();
  },
  computed: {},
  methods: {
    async fetch_data() {
      let credentials = {
        PeriodDT: this.PeriodDT,
      };
      this.$store.dispatch("period/get_period_detail", credentials).then(
        (response) => {
          //   console.log(response);
          const DateArray = response.PeriodDetail.CloseTime.split(" ");
          this.CloseTime =
            DateArray[1].split(":")[0] + ":" + DateArray[1].split(":")[1];
          for (let Hp of response.HPList) {
            let NewHP = {};
            switch (Hp["BetTypeDetailID"]) {
              case "1": //3 บน
                NewHP["number"] = Hp["BetNumber"];
                NewHP["x"] = this.Permutations(Hp["BetNumber"]).length;
                NewHP["p1"] = Hp["Percent"];
                this.HP_3.push(NewHP);
                break;
              //       case "2": //3โต๊ด
              //         // console.log("3 โต๊ด");
              //         // console.log(Hp);
              //         break;
              case "4": //2 บน+ล่าง
                NewHP["number"] = Hp["BetNumber"];
                NewHP["p1"] = Hp["Percent"];
                this.HP_2.push(NewHP);
                break;
            }
            //     //console.log(Hp);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    get_3hp_text(HP_3) {
      if (HP_3["p1"] == "50")
        return HP_3["number"] + " x " + HP_3["x"] + " = จ่ายครึ่ง";
      return HP_3["number"] + " x " + HP_3["x"];
    },
    get_2hp_text(HP_2) {
      if (HP_2["p1"] == "50") return HP_2["number"] + " = จ่ายครึ่ง";
      return HP_2["number"];
    },
    async print() {
      console.log("printing..");

      //   this.$refs.PrintMe.style.textAlign = "left"
      let el = this.$refs.PrintMe;
      //   el.style.textAlign  = "justify"
      const options = {
        type: "dataURL",
        backgroundColor: "#fff",
        width: 600,
        windowWidth: 600,
        left: 50,
      };
      const printCanvas = await this.$html2canvas(el, options);

      if (options.type && options.type === "dataURL") {
        // console.log(printCanvas);
        // this.output = printCanvas;
        const link = document.createElement("a");
        link.setAttribute("download", "P-" + this.PeriodDT + ".png");
        link.setAttribute(
          "href",
          printCanvas
        );
        link.click();
      } else {
        console.warn(
          "Vue Html2Canvas Warn: Invalid option type. Use 'dataURL' instead. Returning canvas."
        );
        //return printCanvas;
      }

      //   const link = document.createElement("a");
      //   link.setAttribute("download", "download.png");
      //   link.setAttribute(
      //     "href",
      //     printCanvas
      //       .toDataURL("image/png")
      //       .replace("image/png", "image/octet-stream")
      //   );
      //   link.click();

      console.log("done");
    },
  },
  data: () => ({
    HostName: sessionStorage.getItem("nickname"),
    PeriodDT: "",
    CloseTime: "",
    HP_3: [],
    HP_2: [],
    // output: null,
  }),
};
</script>

<style scoped></style>
